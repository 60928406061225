<template>
  <div
    class="Search-style-component"
    :class="{ 'search-style': classToUse && classToUse != null }"
    v-click-outside="handleUpdateDetailsShow"
  >
    <label v-if="label != null">{{ label }} </label>
    <div class="search-box">
      <font-awesome-icon
        icon="search"
        class="icon"
        @click="showDetails = !showDetails"
        v-if="getSearchLoading === false"
      />
      <font-awesome-icon
        icon="circle-notch"
        class="icon icon-loader"
        fa-spin
        v-if="getSearchLoading"
        :pulse="true"
      />

      <input
        @input="debounceInput"
        v-model="searchNum"
        class="search-input"
        :class="{ 'search-input-resize': showDetails == false }"
        placeholder="Chercher"
      />
      <font-awesome-icon
        icon="times"
        class="icon-right"
        @click="resetSearchNumDossier"
        v-if="showDetails == true"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ClickOutside from 'vue-click-outside';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      searchNum: this.value,
      showDetails: false
    };
  },
  props: {
    value: { require: true },
    label: { default: null },
    classToUse: { required: false }
  },
  methods: {
    debounceInput: _.debounce(function(e) {
      this.searchNum = e.target.value;
      this.$emit('changeSearchValue', this.searchNum);
    }, 500),
    resetSearchNumDossier() {
      this.searchNum = null;
      this.$emit('changeSearchValue', this.searchNum);
    },
    handleUpdateDetailsShow() {
      this.showDetails = false;
    }
  },
  computed: {
    ...mapGetters(['getSearchLoading'])
  },
  directives: {
    ClickOutside
  },
  watch: {
    value: function() {
      this.searchNum = this.value;
    }
  }
};
</script>
<style scoped lang="scss">
.Search-style-component {
  .search-box {
    background-color: #fdfbfb;
    position: relative;
    padding: 5px;
    color: #a2a2a2;
    background-color: #f9f9f9;
    border: none;
    border-radius: 19px;
    min-width: 40px;

    .icon {
      position: absolute;
      font-size: 14px;
      top: 8px;
      left: 14px;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-right {
      position: absolute;
      font-size: 12px;
      top: 8px;
      right: 8px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-loader {
      color: #2dabe2;
    }
    .search-input {
      height: 18px;
      width: 160px;
      padding: 3px 3px 3px 33px;
      border-radius: 3px;
      box-sizing: border-box;
      box-shadow: none;
      border: none;
      opacity: 0.5;
      outline: none;
      // color: #a2a2a2;
      // font-size: 11px;
      // background-color: #f9f9f9;
      color: #232222;
      font-weight: 700;
      font-size: 11px;
      background-color: #fdfbfb;
      &:focus,
      &:active {
        opacity: 1;
        .icon {
          color: #2dabe2;
        }
      }
    }
    .search-input-resize {
      width: 0px;
      padding: 0px;
    }
  }
  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    color: #5d5d5d;
  }
}
.search-style {
  .search-box {
    background-color: #cdcced;
    height: 100%;
    .search-input {
      height: 25px;
      background-color: #cdcced;
    }
    .icon,
    .icon-right {
      color: #fff;
      top: 12px;
    }
  }
}
</style>
